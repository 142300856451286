import { NgModule } from '@angular/core';

import {
  CosGlobalHeaderCenterComponent,
  CosGlobalHeaderLogoComponent,
  CosGlobalHeaderNavComponent,
} from './components';
import { CosGlobalHeaderSecondaryComponent } from './components/global-header-secondary';
import { CosGlobalHeaderComponent } from './global-header.component';
import { CosGlobalHeaderLocalState } from './global-header.local-state';

const components = [
  CosGlobalHeaderComponent,
  CosGlobalHeaderCenterComponent,
  CosGlobalHeaderNavComponent,
  CosGlobalHeaderLogoComponent,
  CosGlobalHeaderSecondaryComponent,
];

@NgModule({
  imports: [...components],
  exports: [...components],
  /**
   * The local state service is provided here so it can be used directly as a Facade
   * by applications that uses the global header.
   */
  providers: [CosGlobalHeaderLocalState],
})
export class CosGlobalHeaderModule {}
