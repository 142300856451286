import { Injectable } from '@angular/core';

import { LocalState, writableProp } from '@cosmos/state';

@Injectable()
export class CosGlobalHeaderLocalState extends LocalState<CosGlobalHeaderLocalState> {
  secondaryOpen = writableProp(false);

  toggleSecondaryOpen() {
    this.secondaryOpen = !this.secondaryOpen;
  }
}
