<div class="cos-global-header-primary-wrapper">
  <div class="cos-global-header-primary">
    <div class="cos-global-header-primary__logo">
      <ng-content select="cos-global-header-logo" />
    </div>
    <div class="cos-global-header-primary__center">
      <ng-content select="cos-global-header-center" />
    </div>
    <div class="cos-global-header-primary__nav">
      <ng-content select="cos-global-header-nav" />
    </div>
  </div>
</div>

@if (state.secondaryOpen) {
  <div class="cos-global-header-secondary">
    <ng-content select="cos-global-header-secondary" />
  </div>
}
